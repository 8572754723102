import React, { createContext, Component } from 'react';

export const OdooContext = createContext();

class OdooContextProvider extends Component {
    state = {
        username: '',
        loggedIn: false,
        language: 'en_US',
        disablePrev: false,
        disableNext: false,
    }

    setUserName = (username) => {
        this.setState({username: username})
    }

    setLoginStatus = (value) => {
        this.setState({loggedIn: value})
    }

    changeLanguage = (newLanguage) => {
        this.setState({language: newLanguage,})
    }

    changePrevNext = (value) => {
        this.setState({disablePrev: value, disableNext: value});
    }

    componentDidMount() {
        let language = localStorage.getItem('UserLanguage');
        if(language) {
            this.setState({language});
        }
    }

    render() {
        return (
            <OdooContext.Provider value={{...this.state, setUserName: this.setUserName, hideSideNav: this.hideSideNav, setLoginStatus: this.setLoginStatus, changeLanguage: this.changeLanguage, toRefreshList: this.toRefreshList, setToRefreshListPersonal: this.setToRefreshListPersonal, setToRefreshListHistory: this.setToRefreshListHistory, setToRefreshListProduct: this.setToRefreshListProduct, setToRefreshListLoan: this.setToRefreshListLoan, changePrevNext: this.changePrevNext}} >
                {this.props.children}
            </OdooContext.Provider>
        )
    }
}

export default OdooContextProvider;
