import React, { Component } from "react";
import './App.css';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

import HomeScreen from "./components/HomeScreen";
import OdooContextProvider from "./contexts/OdooContext";
import Navbar from './components/Navbar';
import SignIn from "./components/login/Signin";

class App extends Component {
	constructor() {
		super();
		this.state = {
			isUserAuthenticated: false,
			pageLoad: true,
		};
	}

	componentDidMount() {
		let accessToken = localStorage.getItem("AccessToken");
		if (accessToken) {
			this.setState({ isUserAuthenticated: true, pageLoad: false, });
		} else {
			this.setState({ isUserAuthenticated: false, pageLoad: false, });
		}
	}

	render() {
		return (
			<OdooContextProvider>
				<div className="App">
					<Router>
						<Navbar>
							<Switch>
								<Route
									exact
									path="/"
									render={() => {
										return this.state.isUserAuthenticated ? (
											<Redirect to="/home" />
										) : (
											<Redirect to="/login" />
										);
									}}
								/>
								<Route
									path="/login"
									exact={true}
									activeClassName="is-active"
									render={() => {
										return this.state.isUserAuthenticated ? (
											<Redirect to="/home" />
										) : null;
									}}
									component={SignIn}
								/>
								<Route
									path="/home"
									exact={true}
									activeClassName="is-active"
									render={() => {
										return (!(this.state.isUserAuthenticated) && (
											<Redirect to="/login" />
										));
									}}
									component={HomeScreen}
								/>
								<Route
									path="*"
									exact
									render={() => {
										return this.state.isUserAuthenticated ? (
											<Redirect to="/home" />
										) : (
											<Redirect to="/login" />
										);
									}}
								/>
							</Switch>
						</Navbar>
					</Router>
				</div>
			</OdooContextProvider>
		);
	}
}

export default App;
