import { observable, action } from 'mobx';
import { LoanDetailsMapping, PersonalDetailsMapping, GeneralDetailsToSaveMapping } from '../components/utils/Common';

class GeneralStore {
    @observable generalData = [];
    @observable personalData = [];
    @observable personalDataCompanyExperienceData = [];
    @observable personalDataCustomerFamilyData = [];
    @observable personalDataCustomerEducationData = [];
    @observable loanInfoData = [];
    @observable bankDetailsData = [];
    @observable vehicleRegistrationData = [];
    @observable billDetailsData = [];
    @observable toReloadGeneral = true;
    @observable toReloadPersonal = true;
    @observable toReloadLoanInfo = true;
    @observable disposition = '';
    @observable sub_disposition = '';
    @observable appo_date = '';
    @observable no_of_visits = '';
    @observable email = '';
    @observable name = '';
    @observable phone = '';
    @observable campaign = '';
    @observable sales_team = '';
    @observable product_type = '';
    @observable description = '';
    @observable remarks = '';
    @observable channel = '';
    @observable callback_date = '';
    @observable meeting_city_id = '';
    @observable meeting_district_id = '';
    @observable existing_credit_card = '';
    @observable isEditable = false;
    @observable national_id = '';
    @observable street = '';
    @observable street_2 = '';
    @observable city = '';
    @observable state = '';
    @observable country = '';
    @observable x_district = '';
    @observable zip = '';
    @observable birth_date = '';
    @observable age = '';
    @observable marital_status = '';
    @observable monthly_income = '';
    @observable annual_income = '';
    @observable income = '';
    @observable income_type = '';
    @observable salary_type = '';
    @observable no_of_children = '';
    @observable no_of_family_mem = '';
    @observable work_contr = '';
    @observable salary_type = '';
    @observable residence_type = '';
    @observable gender = '';
    @observable voter_id = '';
    @observable passport_id = '';
    @observable pan_no = '';
    @observable address = '';
    @observable occupation = '';
    @observable occupation_1 = '';
    @observable occupation_2 = '';
    @observable other_occupation = '';

    @observable leadDataTime = '';
    @observable leadData = [];
    
    @observable loanInfoDetails_loan_existing = '';
    @observable loanInfoDetails_life_insurance_contr = '';
    @observable loanInfoDetails_term_6months = '';
    @observable loanInfoDetails_insure1yr = '';
    @observable loanInfoDetails_business_reg = '';
    @observable loanInfoDetails_business = '';
    @observable loanInfoDetails_cons_dur_loan = '';

    @observable highlightedFields = [];

    @observable personal_partnerCompanyExperience_company_name = '';
    @observable personal_partnerCompanyExperience_type_company = '';
    @observable personal_partnerCompanyExperience_designation = '';
    @observable personal_partnerCompanyExperience_employee_type = '';
    @observable personal_partnerCompanyExperience_tax_paid_by_employee = '';
    @observable personal_partnerCompanyExperience_employment_from = '';
    
    @observable personal_partnerCustomerFamily_birth_date_sp = '';
    @observable personal_partnerCustomerFamily_mobile = '';
    @observable personal_partnerCustomerFamily_spouse_name = '';
    @observable personal_partnerCustomerFamily_child_name1 = '';
    @observable personal_partnerCustomerFamily_child_name2 = '';
    @observable personal_partnerCustomerFamily_child_name3 = '';
    @observable personal_partnerCustomerFamily_dob1 = '';
    @observable personal_partnerCustomerFamily_dob2 = '';
    @observable personal_partnerCustomerFamily_dob3 = '';
    @observable personal_partnerCustomerFamily_child_gender1 = '';
    @observable personal_partnerCustomerFamily_child_gender2 = '';
    @observable personal_partnerCustomerFamily_child_gender3 = '';
    
    @observable personal_partnerCustomerEducation_institution_name = '';
    @observable personal_partnerCustomerEducation_degree = '';
    
    @observable loanInfo_bankDetails_bnk_stmnt = '';
    @observable loanInfo_bankDetails_bnk = '';
    @observable loanInfo_bankDetails_bnk_m1 = '';
    @observable loanInfo_bankDetails_big_tran_m1 = '';
    @observable loanInfo_bankDetails_small_tran_m1 = '';
    @observable loanInfo_bankDetails_bnk_m2 = '';
    @observable loanInfo_bankDetails_big_tran_m2 = '';
    @observable loanInfo_bankDetails_small_tran_m2 = '';
    @observable loanInfo_bankDetails_bnk_m3 = '';
    @observable loanInfo_bankDetails_big_tran_m3 = '';
    @observable loanInfo_bankDetails_small_tran_m3 = '';
    @observable loanInfo_bankDetails_bnk_m4 = '';
    @observable loanInfo_bankDetails_big_tran_m4 = '';
    @observable loanInfo_bankDetails_small_tran_m4 = '';

    @observable loanInfo_vehicleRegistrationDetails_vehicle_reg = '';
    @observable loanInfo_vehicleRegistrationDetails_vehicle_reg_type = '';
    @observable loanInfo_vehicleRegistrationDetails_vehicle_pur_date = '';
    @observable loanInfo_vehicleRegistrationDetails_vehicle_value = '';
    @observable loanInfo_vehicleRegistrationDetails_other_veh_owner = '';
    @observable loanInfo_vehicleRegistrationDetails_other_veh_contact = '';

    @observable loanInfo_billDetails_utility_bills = '';
    @observable loanInfo_billDetails_cable_tv_bill = '';
    @observable loanInfo_billDetails_net_bill = '';
    @observable loanInfo_billDetails_water_bill = '';
    @observable loanInfo_billDetails_evn_bill = '';
    @observable loanInfo_billDetails_evn_bill_holder = '';
    @observable loanInfo_billDetails_evn_bill_amnt = '';

    @action
    onChangeText = (key, val) => {
        this[key] = val;
    }

    @action
    setLeadData = (value) => {
        this.leadData = value;
    }

    @action
    setLeadDataTime = (value) => {
        this.leadDataTime = value;
    }

    @action
    setLeadListData = (leadID, dataList) => {
        let leadData = this.leadData;
        for(let i = 0; i < leadData.length; i++) {
            if(leadData[i].id === leadID) {
                for(let j = 0; j < dataList.length; j++) {
                    leadData[i][dataList[j].key] = dataList[j].value;
                }
            }
        }

        this.leadData = leadData;
    }

    @action
    setGeneralData = (value) => {
        this.generalData = value;
    }

    @action
    setPersonalData = (value) => {
        this.personalData = value;
    }

    @action
    setPersonalCompanyExperienceData = (value) => {
        this.personalDataCompanyExperienceData = value;
    }

    @action
    setPersonalCustomerFamilyData = (value) => {
        this.personalDataCustomerFamilyData = value;
    }

    @action
    setPersonalCustomerEducationData = (value) => {
        this.personalDataCustomerEducationData = value;
    }

    @action
    setLoanInfoData = (value) => {
        this.loanInfoData = value;
    }

    @action
    setBankDetailsData = (value) => {
        this.bankDetailsData = value;
    }

    @action
    setVehicleRegistrationData = (value) => {
        this.vehicleRegistrationData = value;
    }

    @action
    setBillDetailsData = (value) => {
        this.billDetailsData = value;
    }

    @action
    toggleEdit = (value) => {
        this.isEditable = value;
    }

    @action
    cancelEdit = () => {
        this.isEditable = false;
        let keyType = 'loanInfoDetails_';
        let loanInfoData = this.loanInfoData;
        for(let i = 0; i < loanInfoData.length; i++) {
            for (let key of Object.keys(loanInfoData[i])) {
                this[`${keyType}${key}`] = loanInfoData[i][`${keyType}${key}`];
            }
        }

        keyType = 'loanInfo_bankDetails_';
        let bankDetailsData = this.bankDetailsData;
        for(let i = 0; i < bankDetailsData.length; i++) {
            for (let key of Object.keys(bankDetailsData[i])) {
                this[`${keyType}${key}`] = bankDetailsData[i][`${keyType}${key}`];
            }
        }

        keyType = 'loanInfo_vehicleRegistrationDetails_';
        let vehicleRegistrationData = this.vehicleRegistrationData;
        for(let i = 0; i < vehicleRegistrationData.length; i++) {
            for (let key of Object.keys(vehicleRegistrationData[i])) {
                this[`${keyType}${key}`] = vehicleRegistrationData[i][`${keyType}${key}`];
            }
        }

        keyType = 'loanInfo_billDetails_';
        let billDetailsData = this.billDetailsData;
        for(let i = 0; i < billDetailsData.length; i++) {
            for (let key of Object.keys(billDetailsData[i])) {
                this[`${keyType}${key}`] = billDetailsData[i][`${keyType}${key}`];
            }
        }

        keyType = 'personal_partnerCompanyExperience_';
        let personalDataCompanyExperienceData = this.personalDataCompanyExperienceData;
        for(let i = 0; i < personalDataCompanyExperienceData.length; i++) {
            for (let key of Object.keys(personalDataCompanyExperienceData[i])) {
                this[`${keyType}${key}`] = personalDataCompanyExperienceData[i][`${keyType}${key}`];
            }
        }

        keyType = 'personal_partnerCustomerFamily_';
        let personalDataCustomerFamilyData = this.personalDataCustomerFamilyData;
        for(let i = 0; i < personalDataCustomerFamilyData.length; i++) {
            for (let key of Object.keys(personalDataCustomerFamilyData[i])) {
                this[`${keyType}${key}`] = personalDataCustomerFamilyData[i][`${keyType}${key}`];
            }
        }

        keyType = 'personal_partnerCustomerEducation_';
        let personalDataCustomerEducationData = this.personalDataCustomerEducationData;
        for(let i = 0; i < personalDataCustomerEducationData.length; i++) {
            for (let key of Object.keys(personalDataCustomerEducationData[i])) {
                this[`${keyType}${key}`] = personalDataCustomerEducationData[i][`${keyType}${key}`];
            }
        }

        let personalData = this.personalData;
        for(let i = 0; i < personalData.length; i++) {
            for (let key of Object.keys(personalData[i])) {
                this[key] = personalData[i][key];
            }
        }

        let generalData = this.generalData;
        for(let i = 1; i < generalData.length; i++) {
            for (let key of Object.keys(generalData[i])) {
                if((key == 'appo_date') || (key == 'callback_date')) {
                    if((generalData[i][key] != this[key]) && (this[key] != null) && (generalData[i][key].length > 0) && (generalData[i][key].length != 'undefined')) {
                        this[key] = generalData[i][key];
                    }
                } else {
                    this[key] = generalData[i][key];
                }
            }
        }
    }

    @action
    setGeneralReload = (value) => {
        this.toReloadGeneral = value;
    }

    @action
    setPersonalReload = (value) => {
        this.toReloadPersonal = value;
    }

    @action
    setLoanInfoReload = (value) => {
        this.toReloadLoanInfo = value;
    }

    @action
    setHighlightedFields = (value) => {
        this.highlightedFields = value;
    }
}

export default new GeneralStore;
