import React, { Component } from "react";
import { withRouter, } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { observer, inject } from 'mobx-react';
import StarRateIcon from '@material-ui/icons/StarRate';
import TextField from '@material-ui/core/TextField';

import CustomTableNew from './utils/CustomTableNew';
import { LoanDetailsMapping, ExistingLoansMapping, BillDetailsMapping, VehicleRegistrationDetailsMapping, BankDetailsMapping, availableValues, BankDetailsDropdowns, BillDetailsDropdowns, VehicleRegistrationDetailsDropdowns, DateValues } from './utils/Common';

const styles = (theme) => ({
    
})

function containsObjectBnk(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].name === obj.name) {
            return true;
        }
    }

    return false;
}

function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].title === obj.title) {
            return true;
        }
    }

    return false;
}

function getMonthData() {
    let month = new Array();
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    return month;
}

@inject('GeneralStore')
@observer
class TabLoan extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loanInfoIsLoading: false,
            loanInfoIsLoadingMessage: 'Please wait while we load the data...',
            loanInfoShowMessage: false,
            loanInfoMessage: '',
            loanInfoEditable: false,
            loanInfoKeys:[],
            loanInfoData:[],
            bankDetailsData:[],
            bankDetailsColumns:[],
            bankDetailsAddedRows: [],
            bankDetailsShowMessage: false,
            bankDetailsMessage: '',
            vehicleRegistrationData:[],
            vehicleRegistrationColumns:[],
            vehicleRegistrationAddedRows: [],
            vehicleRegistrationShowMessage: false,
            vehicleRegistrationMessage: '',
            billDetailsData:[],
            billDetailsColumns:[],
            billDetailsAddedRows: [],
            billDetailsShowMessage: false,
            billDetailsMessage: '',
            existingLoansData:[],
            existingLoansColumns:[],
            existingLoansAddedRows: [],
            existingLoansShowMessage: false,
            existingLoansMessage: '',
            selectedLeadId: this.props.selectedLeadId,
        }
    }

    existingLoansSetAddedRows = (rows) => {
        this.setState({existingLoansAddedRows: rows})
    }
    
	existingLoansChangeAddedRows = (value) => {
        let initialized = value.map(row => (Object.keys(row).length && row));
        this.setState({existingLoansAddedRows: initialized})
    }

    existingLoansCommitChanges = async ({added, changed, deleted}) => {
        let changedRows;
        if (added) {
            const startingAddedId = this.state.existingLoansData.length > 0 ? this.state.existingLoansData[this.state.existingLoansData.length - 1].id + 1 : 0;
            changedRows = [
                ...this.state.existingLoansData,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row,
                })),
            ];
        }
        
        if (changed) {
            changedRows = this.state.existingLoansData.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
        }

        let oldData = this.state.existingLoansData;
        this.setState({existingLoansData: changedRows, beforeChangedLoansData: oldData}, () => {
            if(added) {
                this.saveTableData(added, changedRows, 'added', 'existingLoansData', 'beforeChangedLoansData');
            } else if (changed) {
                this.saveTableData(changed, changedRows, 'changed', 'existingLoansData', 'beforeChangedLoansData');
            }
        })
    }

    existingLoansGetEditingRowIds = (rows) => {
        this.setState({existingLoansEditingRowIds: rows})
    }

    existingLoansSetRowChanges = (row) => {
        this.setState({existingLoansRowChanges: row})
    }

    changeText = (key, myValue) => {
        if(key == 'loanInfo_bankDetails_bnk_stmnt') {
            let monthData = getMonthData();
            let currentDate = new Date();

            let myMonth = currentDate.getMonth() - 1;
            if(myMonth < 0) {
                myMonth = 12 - myMonth;
            }
            this.props.GeneralStore.onChangeText('loanInfo_bankDetails_bnk_m1', monthData[myMonth])

            myMonth = currentDate.getMonth() - 2;
            if(myMonth < 0) {
                myMonth = 12 - myMonth;
            }
            this.props.GeneralStore.onChangeText('loanInfo_bankDetails_bnk_m2', monthData[myMonth])

            myMonth = currentDate.getMonth() - 3;
            if(myMonth < 0) {
                myMonth = 12 - myMonth;
            }
            this.props.GeneralStore.onChangeText('loanInfo_bankDetails_bnk_m3', monthData[myMonth])

            myMonth = currentDate.getMonth() - 4;
            if(myMonth < 0) {
                myMonth = 12 - myMonth;
            }
            this.props.GeneralStore.onChangeText('loanInfo_bankDetails_bnk_m4', monthData[myMonth])
        }
        this.props.GeneralStore.onChangeText(key, myValue)
    }

    saveTableData = async (dataToSave, allChangedData, type, allChangeDataKey, oldChangeDataKey) => {
        let dataToSendForChange = {};
        if(type == 'changed') {
            let allKeys = Object.keys(dataToSave);
            let myKey = allKeys[0];
            for(let i = 0; i < allChangedData.length; i++) {
                if(allChangedData[i].id == myKey) {
                    dataToSendForChange = allChangedData[i];
                    break;
                }
            }
        }

        this.setState({loanInfoEditable: false, loanInfoIsLoading: true, loanInfoIsLoadingMessage: 'Please wait while we save the table data...', loanInfoShowMessage: false, loanInfoMessage: ''})

        let url = null;
        let keyType = 'loanInfo_existingLoans_';
        let dataToSend = {}
        if(type == 'changed') {
            url = 'loan_details/update';
            let keys = Object.keys(ExistingLoansMapping);
            for(let key in keys) {
                dataToSend[keys[key]] = dataToSendForChange[`${keyType}${keys[key]}`] ? dataToSendForChange[`${keyType}${keys[key]}`] : '';
            }
        } else {
            url = 'loan_details/create';
            let keys = Object.keys(ExistingLoansMapping);
            for(let key in keys) {
                dataToSend[keys[key]] = dataToSave[0][`${keyType}${keys[key]}`] ? dataToSave[0][`${keyType}${keys[key]}`] : '';
            }
        }
        dataToSend['lead_id'] = this.state.selectedLeadId;
        
        await fetch('/api/tsr/' + url, {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
                'Content-Type': 'application/json; ',
                Authorization: `Bearer ${this.state.token}`,
            },
        })
        .then(response => {
            if(response.status == 200) {
                return response.json()
            } else {
                return "NA"
            }
        })
        .then(data => {
            if(data == "NA") {
                let oldData = this.state[oldChangeDataKey];
                this.setState({[allChangeDataKey]: oldData, loanInfoIsLoading: false, loanInfoShowMessage: true, loanInfoMessage: 'There was some error. Please check the data and try again', messageColor: 'red'})
            } else {
                if(data.status == 'True') {
                    this.setState({loanInfoIsLoading: false, loanInfoShowMessage: true, loanInfoMessage: data.msg, messageColor: 'green'})
                } else {
                    let oldData = this.state[oldChangeDataKey];
                    this.setState({[allChangeDataKey]: oldData, loanInfoIsLoading: false, loanInfoShowMessage: true, loanInfoMessage: data.msg, messageColor: 'red'})
                }
            }
        })
        .catch(error => {
            console.log(error)
            let oldData = this.state[oldChangeDataKey];
            this.setState({[allChangeDataKey]: oldData, loanInfoIsLoading: false, loanInfoShowMessage: true, loanInfoMessage: 'There was some error. Please check the data and try again', messageColor: 'red'})
        })
    }

    
    prepareLoanInfoData = async (myLoanInfo) => {
        let loanInfoData = [];
        let bankDetailsData = [];
        let vehicleRegistrationDetailsData = [];
        let billDetailsData = [];
        let existingLoansData = [];
        let existingLoansColumns = [];

        let totalElements = 0;
        let totalElementsBankDetails = 0;
        let totalElementsVehicleRegistrationDetails = 0;
        let totalElementsBillDetails = 0;
        let myData = {};
        let myDataBankDetails = {};
        let myDataVehicleRegistrationDetails = {};
        let myDataBillDetails = {};

        for(let i = 0; i < myLoanInfo.length; i++) {
            let dataPushed = false;
            let dataPushedBankDetails = false;
            let dataPushedVehicleRegistrationDetails = false;
            let dataPushedBillDetails = false;
            let dataVisited = false;
            for (let key of Object.keys(myLoanInfo[i])) {
                if(key == 'bank_details') {
                    let myBankDetailsData = myLoanInfo[i][key];
                    // if(!('big_tran_m1' in myBankDetailsData)) {
                    //     myBankDetailsData['big_tran_m1'] = '';
                    // }
                    for (let key1 of Object.keys(BankDetailsMapping)) {
                        let keyName = `loanInfo_bankDetails_${key1}`;
                        dataPushedBankDetails = false;
                        if(this.props.GeneralStore.toReloadLoanInfo) {
                            this.props.GeneralStore.onChangeText(keyName, myBankDetailsData[key1]);
                        }
                        myDataBankDetails[key1] = myBankDetailsData[key1];
                        totalElementsBankDetails++;
                        if((totalElementsBankDetails % 7 == 0) && (totalElementsBankDetails > 1)) {
                            bankDetailsData.push(myDataBankDetails);
                            myDataBankDetails = {};
                            dataPushedBankDetails = true;
                        }
                    }
                } else if (key == 'vehicle_reg_details') {
                    let myVehicleRegistrationDetailsData = myLoanInfo[i][key];
                    for (let key1 of Object.keys(myVehicleRegistrationDetailsData)) {
                        let keyName = `loanInfo_vehicleRegistrationDetails_${key1}`;
                        dataPushedVehicleRegistrationDetails = false;
                        if(this.props.GeneralStore.toReloadLoanInfo) {
                            this.props.GeneralStore.onChangeText(keyName, myVehicleRegistrationDetailsData[key1]);
                        }
                        myDataVehicleRegistrationDetails[key1] = myVehicleRegistrationDetailsData[key1];
                        totalElementsVehicleRegistrationDetails++;
                        if((totalElementsVehicleRegistrationDetails % 3 == 0) && (totalElementsVehicleRegistrationDetails > 1)) {
                            vehicleRegistrationDetailsData.push(myDataVehicleRegistrationDetails);
                            myDataVehicleRegistrationDetails = {};
                            dataPushedVehicleRegistrationDetails = true;
                        }
                    }
                } else if (key == 'bill_details') {
                    let myBillDetailsData = myLoanInfo[i][key];
                    for (let key1 of Object.keys(myBillDetailsData)) {
                        let keyName = `loanInfo_billDetails_${key1}`;
                        dataPushedBillDetails = false;
                        if(this.props.GeneralStore.toReloadLoanInfo) {
                            this.props.GeneralStore.onChangeText(keyName, myBillDetailsData[key1]);
                        }
                        myDataBillDetails[key1] = myBillDetailsData[key1];
                        totalElementsBillDetails++;
                        if((totalElementsBillDetails % 4 == 0) && (totalElementsBillDetails > 1)) {
                            billDetailsData.push(myDataBillDetails);
                            myDataBillDetails = {};
                            dataPushedBillDetails = true;
                        }
                    }
                } else if (key == 'existing_loans') {
                    let myData = myLoanInfo[i][key];
                    for(let j = 0; j < myData.length; j++) {
                        let valuePair = {};
                        for (let key1 of Object.keys(myData[j])) {
                            let keyName = `loanInfo_existingLoans_${key1}`;
                            let keyPair = {};
                            keyPair.name = keyName;
                            keyPair.title = ExistingLoansMapping[key1];
                            if(!containsObject(keyPair, existingLoansColumns)) {
                                existingLoansColumns.push(keyPair);
                            }
                            valuePair[keyName] = myData[j][key1];
                        }
                        valuePair.id = `loanInfo_existingLoans_${j}`;
                        existingLoansData.push(valuePair)
                    }
                    if(myData.length == 0) {
                        for (let key1 of Object.keys(ExistingLoansMapping)) {
                            let keyName = `loanInfo_existingLoans_${key1}`;
                            let keyPair = {};
                            keyPair.name = keyName;
                            keyPair.title = ExistingLoansMapping[key1];
                            if(!containsObject(keyPair, existingLoansColumns)) {
                                existingLoansColumns.push(keyPair);
                            }
                        }
                    }
                } else {
                    let myKey = `loanInfoDetails_${key}`;
                    if(this.props.GeneralStore.toReloadLoanInfo) {
                        this.props.GeneralStore.onChangeText(myKey, myLoanInfo[i][key]);
                    }
                    dataPushed = false;
                    myData[key] = myLoanInfo[i][key];
                    totalElements++;
                    if((totalElements % 5 == 0) && (totalElements > 1)) {
                        loanInfoData.push(myData);
                        myData = {};
                        dataPushed = true;
                    }
                }
            }
            if(!dataPushed) {
                loanInfoData.push(myData);
            }
            if(!dataPushedBankDetails) {
                bankDetailsData.push(myDataBankDetails);
            }
            if(!dataPushedBillDetails) {
                billDetailsData.push(myDataBillDetails);
            }
            if(!dataPushedVehicleRegistrationDetails) {
                vehicleRegistrationDetailsData.push(myDataVehicleRegistrationDetails);
            }
        }

        this.setState({existingLoansColumns, existingLoansData,}, () => {
            if(this.props.GeneralStore.toReloadLoanInfo) {
                this.props.GeneralStore.setLoanInfoData(loanInfoData);
                this.props.GeneralStore.setBankDetailsData(bankDetailsData);
                this.props.GeneralStore.setVehicleRegistrationData(vehicleRegistrationDetailsData);
                this.props.GeneralStore.setBillDetailsData(billDetailsData);
                this.props.GeneralStore.setLoanInfoReload(false);
            }
            this.setState({loanInfoShowMessage: false, loanInfoMessage: '', loanInfoIsLoading: false, loanInfoIsLoadingMessage: ''})
        })
    }

    getLoanInfoData = async () => {
        this.setState({loanInfoShowMessage: false, loanInfoMessage: '', loanInfoIsLoading: true, loanInfoIsLoadingMessage: 'Please wait while we load the data...'})

        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/lead_details?lead_id=${this.state.selectedLeadId}&lang=${this.context.language}`);
		} catch (error) {
			console.log(error)
			this.setState({loanInfoShowMessage: true, loanInfoMessage: 'Some error occured while fetching the loan info details.', loanInfoIsLoading: false, loanInfoIsLoadingMessage: '', messageColor: 'red'})
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
            console.log(myResponse)
            this.setState({loanInfoShowMessage: true, loanInfoMessage: 'Some error occured while fetching the loan info details.', loanInfoIsLoading: false, loanInfoIsLoadingMessage: '', messageColor: 'red'})
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let myloanData = jsonResp.res[0]['Loan,Bills And Statements'];
                await this.prepareLoanInfoData(myloanData)
			} else {
                this.setState({loanInfoShowMessage: true, loanInfoMessage: 'Some error occured while fetching the loan info details.', loanInfoIsLoading: false, loanInfoIsLoadingMessage: '', messageColor: 'red'})
            }
		} else {
            this.setState({loanInfoShowMessage: true, loanInfoMessage: 'Some error occured while fetching the loan info details.', loanInfoIsLoading: false, loanInfoIsLoadingMessage: '', messageColor: 'red'})
        }
    }

    getToken = async () => {
        let token = await localStorage.getItem('AccessToken');
        if(token && token != 'undefined') {
            console.log(token)
            return token;
        } else {
            localStorage.removeItem('AccessToken');
            this.props.history.push('login');
        }
    }

    componentWillReceiveProps(props) {
        const { loanTabClicked, selectedLeadId, clearMessageStateTabs } = this.props;
        if (props.clearMessageStateTabs !== clearMessageStateTabs) {
            this.setState({loanInfoIsLoading: false, loanInfoIsLoadingMessage: '', loanInfoShowMessage: false, loanInfoMessage: ''})
        }
        if (props.loanTabClicked !== loanTabClicked) {
            this.getLoanInfoData();
        }
        if(props.selectedLeadId !== selectedLeadId) {
            this.setState({selectedLeadId})
        }
    }

    async componentDidMount() {
        console.log('Personal mounted!')
        let token = await this.getToken();
        this.setState({token})
        await this.getLoanInfoData();
    }

	render() {
		const { classes } = this.props;

		return (
            <React.Fragment>
                {this.state.loanInfoIsLoading ? (
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center'}}>
                        <CircularProgress color='#256cd6' size='50px' style={{color: '#256cd6'}} />
                        <Typography variant="body2" style={{color: '#256cd6'}}>
                            {this.state.loanInfoIsLoadingMessage}
                        </Typography>
                    </div>
                ) : (
                    <React.Fragment>
                        {!!this.state.loanInfoShowMessage &&
                            <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginTop: 20}}>
                                <div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', color: this.state.messageColor ? this.state.messageColor : '#256cd6'}}>
                                    <span style={{fontSize: 20}}>{this.state.loanInfoMessage}</span>
                                </div>
                            </div>
                        }
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingRight: 20,}}>
                            {this.props.GeneralStore.loanInfoData.map((data, index) => (
                                <React.Fragment>
                                    <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                        {Object.entries(data).map(([key,value],i) => (
                                            key != 'type' && (
                                                <React.Fragment>
                                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`loaninfo_${i}`}>
                                                        <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                            {LoanDetailsMapping[key]} {!!this.props.GeneralStore.isEditable && (
                                                                Object.entries(this.props.GeneralStore.highlightedFields).map(([key2,value2],i) => {
                                                                    if(key === value2) {
                                                                        return (<StarRateIcon style={{color: 'red', height: 10, width: 10,}} />);
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                })
                                                            )}
                                                        </Typography>
                                                        {this.props.GeneralStore.isEditable ? (
                                                            <FormControl style={{minWidth: 250}}>
                                                                <Select
                                                                    labelId={`loanInfoDetails_${key}-select-label`}
                                                                    id={`loanInfoDetails_${key}-select-label`}
                                                                    value={this.props.GeneralStore[`loanInfoDetails_${key}`]}
                                                                    onChange={event => {this.changeText(`loanInfoDetails_${key}`, event.target.value)}}
                                                                >
                                                                    {availableValues[`loanInfoDetails_${key}`].map((item) => (
                                                                        <MenuItem key={item.key} value={item.value}>
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        ) : (
                                                            <Typography variant="body2" className={classes.title} style={{paddingLeft: 20}}>
                                                                {availableValues[`loanInfoDetails_${key}`].map((item) => (
                                                                    item.value == value ? item.name : null
                                                                ))}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                    <Divider />
                                                </React.Fragment>
                                            )
                                        ))}
                                    </div>
                                    {((this.props.GeneralStore.loanInfoData.length - 1) != index) &&
                                        <Divider orientation="vertical" flexItem />
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginTop: 20}}>
                            <Typography component="h1" variant="h5" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start'}} align='left'>
                                Bank Details
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingRight: 20,}}>
                                {this.props.GeneralStore.bankDetailsData.map((data, index) => (
                                    <React.Fragment>
                                        <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                            {Object.entries(data).map(([key,value],i) => (
                                                key != 'type' && (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`loanInfo_bankDetails_${i}`}>
                                                            <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                                {BankDetailsMapping[key]} {!!this.props.GeneralStore.isEditable && (
                                                                    Object.entries(this.props.GeneralStore.highlightedFields).map(([key2,value2],i) => {
                                                                        if(key === value2) {
                                                                            return (<StarRateIcon style={{color: 'red', height: 10, width: 10,}} />);
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                )}
                                                            </Typography>
                                                            {this.props.GeneralStore.isEditable ? (
                                                                BankDetailsDropdowns.includes(key) ? (
                                                                    <FormControl style={{minWidth: 250}}>
                                                                        <Select
                                                                            labelId={`loanInfo_bankDetails_${key}-select-label`}
                                                                            id={`loanInfo_bankDetails_${key}-select-label`}
                                                                            value={this.props.GeneralStore[`loanInfo_bankDetails_${key}`]}
                                                                            onChange={event => {this.changeText(`loanInfo_bankDetails_${key}`, event.target.value)}}
                                                                        >
                                                                            {availableValues[`loanInfo_bankDetails_${key}`].map((item) => (
                                                                                <MenuItem key={item.key} value={item.value}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                ) : (
                                                                    <FormControl style={{minWidth: 250}}>
                                                                        <TextField id={`outlined-basic-${key}`} variant="outlined" onChange={event => this.changeText(`loanInfo_bankDetails_${key}`, event.target.value)} value={this.props.GeneralStore[`loanInfo_bankDetails_${key}`]} />
                                                                    </FormControl>
                                                                )
                                                            ) : (
                                                                <Typography variant="body2" className={classes.title} style={{paddingLeft: 20}}>
                                                                    {BankDetailsDropdowns.includes(key) ? (
                                                                        availableValues[`loanInfo_bankDetails_${key}`].map((item) => (
                                                                            item.value == value ? item.name : null
                                                                        ))
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        <Divider />
                                                    </React.Fragment>
                                                )
                                            ))}
                                        </div>
                                        {((this.props.GeneralStore.bankDetailsData.length - 1) != index) &&
                                            <Divider orientation="vertical" flexItem />
                                        }
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginTop: 20}}>
                            <Typography component="h1" variant="h5" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start'}} align='left'>
                                Vehicle Registration Details
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingRight: 20,}}>
                                {this.props.GeneralStore.vehicleRegistrationData.map((data, index) => (
                                    <React.Fragment>
                                        <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                            {Object.entries(data).map(([key,value],i) => (
                                                key != 'type' && (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`loanInfo_vehicleRegistrationDetails_${i}`}>
                                                            <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                                {VehicleRegistrationDetailsMapping[key]} {!!this.props.GeneralStore.isEditable && (
                                                                    Object.entries(this.props.GeneralStore.highlightedFields).map(([key2,value2],i) => {
                                                                        if(key === value2) {
                                                                            return (<StarRateIcon style={{color: 'red', height: 10, width: 10,}} />);
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                )}
                                                            </Typography>
                                                            {this.props.GeneralStore.isEditable ? (
                                                                VehicleRegistrationDetailsDropdowns.includes(key) ? (
                                                                    <FormControl style={{minWidth: 250}}>
                                                                        <Select
                                                                            labelId={`loanInfo_vehicleRegistrationDetails_${key}-select-label`}
                                                                            id={`loanInfo_vehicleRegistrationDetails_${key}-select-label`}
                                                                            value={this.props.GeneralStore[`loanInfo_vehicleRegistrationDetails_${key}`]}
                                                                            onChange={event => {this.changeText(`loanInfo_vehicleRegistrationDetails_${key}`, event.target.value)}}
                                                                        >
                                                                            {availableValues[`loanInfo_vehicleRegistrationDetails_${key}`].map((item) => (
                                                                                <MenuItem key={item.key} value={item.value}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                ) : (
                                                                    DateValues.includes(`loanInfo_vehicleRegistrationDetails_${key}`) ? (
                                                                        <FormControl style={{minWidth: 250}}>
                                                                            <TextField
                                                                                id={`loanInfo_vehicleRegistrationDetails_outlined-basic-${key}`}
                                                                                label={VehicleRegistrationDetailsMapping[key]}
                                                                                type="date"
                                                                                className={classes.textField}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                onChange={event => this.changeText(`loanInfo_vehicleRegistrationDetails_${key}`, event.target.value)}
                                                                                value={this.props.GeneralStore[`loanInfo_vehicleRegistrationDetails_${key}`]}
                                                                            />
                                                                        </FormControl>
                                                                    ) : (
                                                                        <FormControl style={{minWidth: 250}}>
                                                                            <TextField id={`outlined-basic-${key}`} variant="outlined" onChange={event => this.changeText(`loanInfo_vehicleRegistrationDetails_${key}`, event.target.value)} value={this.props.GeneralStore[`loanInfo_vehicleRegistrationDetails_${key}`]} />
                                                                        </FormControl>
                                                                    )
                                                                )
                                                            ) : (
                                                                <Typography variant="body2" className={classes.title} style={{paddingLeft: 20}}>
                                                                    {VehicleRegistrationDetailsDropdowns.includes(key) ? (
                                                                        availableValues[`loanInfo_vehicleRegistrationDetails_${key}`].map((item) => (
                                                                            item.value == value ? item.name : null
                                                                        ))
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        <Divider />
                                                    </React.Fragment>
                                                )
                                            ))}
                                        </div>
                                        {((this.props.GeneralStore.vehicleRegistrationData.length - 1) != index) &&
                                            <Divider orientation="vertical" flexItem />
                                        }
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginTop: 20}}>
                            <Typography component="h1" variant="h5" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start'}} align='left'>
                                Bill Details
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingRight: 20,}}>
                                {this.props.GeneralStore.billDetailsData.map((data, index) => (
                                    <React.Fragment>
                                        <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                            {Object.entries(data).map(([key,value],i) => (
                                                key != 'type' && (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`loanInfo_billDetails_${i}`}>
                                                            <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                                {BillDetailsMapping[key]} {!!this.props.GeneralStore.isEditable && (
                                                                    Object.entries(this.props.GeneralStore.highlightedFields).map(([key2,value2],i) => {
                                                                        if(key === value2) {
                                                                            return (<StarRateIcon style={{color: 'red', height: 10, width: 10,}} />);
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                )}
                                                            </Typography>
                                                            {this.props.GeneralStore.isEditable ? (
                                                                BillDetailsDropdowns.includes(key) ? (
                                                                    <FormControl style={{minWidth: 250}}>
                                                                        <Select
                                                                            labelId={`loanInfo_billDetails_${key}-select-label`}
                                                                            id={`loanInfo_billDetails_${key}-select-label`}
                                                                            value={this.props.GeneralStore[`loanInfo_billDetails_${key}`]}
                                                                            onChange={event => {this.changeText(`loanInfo_billDetails_${key}`, event.target.value)}}
                                                                        >
                                                                            {availableValues[`loanInfo_billDetails_${key}`].map((item) => (
                                                                                <MenuItem key={item.key} value={item.value}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                ) : (
                                                                    <FormControl style={{minWidth: 250}}>
                                                                        <TextField id={`outlined-basic-${key}`} variant="outlined" onChange={event => this.changeText(`loanInfo_billDetails_${key}`, event.target.value)} value={this.props.GeneralStore[`loanInfo_billDetails_${key}`]} />
                                                                    </FormControl>
                                                                )
                                                            ) : (
                                                                <Typography variant="body2" className={classes.title} style={{paddingLeft: 20}}>
                                                                    {BillDetailsDropdowns.includes(key) ? (
                                                                        availableValues[`loanInfo_billDetails_${key}`].map((item) => (
                                                                            item.value == value ? item.name : null
                                                                        ))
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        <Divider />
                                                    </React.Fragment>
                                                )
                                            ))}
                                        </div>
                                        {((this.props.GeneralStore.billDetailsData.length - 1) != index) &&
                                            <Divider orientation="vertical" flexItem />
                                        }
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginTop: 20}}>
                            <Typography component="h1" variant="h5" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start'}} align='left'>
                                Existing Loans
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginBottom: 20,}}>
                                <CustomTableNew
                                    addedRows={this.state.existingLoansAddedRows}
                                    changeAddedRows={this.existingLoansChangeAddedRows}
                                    commitChanges={this.existingLoansCommitChanges}
                                    rows={this.state.existingLoansData}
                                    columns={this.state.existingLoansColumns}
                                    editingRowIds={this.state.existingLoansEditingRowIds}
                                    getEditingRowIds={this.existingLoansGetEditingRowIds}
                                    rowChanges={this.state.existingLoansRowChanges}
                                    setRowChanges={this.existingLoansSetRowChanges}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(TabLoan));
