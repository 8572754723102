import React, { Component } from "react";
import { withRouter, } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { OdooContext } from "../contexts/OdooContext";

import { ProductDetailsMapping, ProductDetailsMapping_Vt, ProductDetailsKeysNotToCheck, PersonalDetailsDropdowns, } from './utils/Common';

const styles = (theme) => ({
    
})

class TabProduct extends Component {

    static contextType = OdooContext;

    constructor(props) {
        super(props)
        this.state = {
            productIsLoading: true,
            productIsLoadingMessage: 'Please wait while we load the data...',
            productShowMessage: false,
            productMessage: '',
            productEditable: false,
            subProductList: [],
            productDetailsEditable: false,
            productIsLoading: false,
            productShowMessage: false,
            productMessage: '',
            productDetailsData: [],
            productDetailsColumns: [],
            productDetailsAddedRows: [],
            productDetailsKey: [],
            selectedLeadId: this.props.selectedLeadId,
        }
    }

    toggleEdit = (key) => {
        this.setState((prevState) => ({
            [key]: !prevState[key],
        }))
    }

    changeText = (key, myValue) => {
        this.setState({[key]: myValue})
    }

    saveProductDetailsData = async (index) => {
        this.setState({[`productDetailsEditable_${index}`]: false, productIsLoading: true, productShowMessage: false, productMessage: ''})

        let dataToSend;
        let urlToCall;
        if(!this.state[`productDetails_added_${index}`]) {
            dataToSend = {
                "lead_id": this.state.selectedLeadId,
                "expected_amount": this.state[`productDetails_expected_amount_${index}`],
                "id": this.state[`productDetails_id_${index}`]
            }
            urlToCall = 'update';
        } else {
            dataToSend = {
                "lead_id": this.state.selectedLeadId,
                "product": this.state[`productDetails_product_${index}`],
                "expected_amount": this.state[`productDetails_expected_amount_${index}`],
                "duration_in_yrs": this.state[`productDetails_duration_in_yrs_${index}`],
            }
            urlToCall = 'create';
        }
        console.log(dataToSend)
        console.log(urlToCall)
        await fetch('/api/tsr/product_details/' + urlToCall, {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
                'Content-Type': 'application/json; ',
                Authorization: `Bearer ${this.state.token}`,
            },
        })
        .then(response => {
            if(response.status == 200) {
                return response.json()
            } else {
                return "NA"
            }
        })
        .then(data => {
            if(data == "NA") {
                if(urlToCall == 'create') {
                    let productDetailsData = this.state.productDetailsData;
                    productDetailsData.splice(index, 1);
                    this.setState({[`productDetailsEditable_${index}`]: undefined, [`productDetails_product_${index}`]: undefined, [`productDetails_expected_amount_${index}`]: undefined, [`productDetails_duration_in_yrs_${index}`]: undefined, productDetailsData, productIsLoading: false, productShowMessage: true, productMessage: 'There was some error. Please check the data and try again', messageColor: 'red'})
                } else {
                    this.setState({[`productDetailsEditable_${index}`]: true, productIsLoading: false, productShowMessage: true, productMessage: 'There was some error. Please check the data and try again', messageColor: 'red'})
                }
            } else {
                if(data.status == 'True') {
                    let productDetailsData = this.state.productDetailsData;
                    for (let key of Object.keys(productDetailsData[index])) {
                        productDetailsData[index][key] = this.state[`productDetails_${key}_${index}`];
                    }
                    this.setState({productDetailsData, productIsLoading: false, productShowMessage: true, productMessage: data.msg, [`productDetails_added_${index}`]: false, messageColor: 'green'})
                } else {
                    if(urlToCall == 'create') {
                        let productDetailsData = this.state.productDetailsData;
                        productDetailsData.splice(index, 1);
                        this.setState({[`productDetailsEditable_${index}`]: undefined, [`productDetails_product_${index}`]: undefined, [`productDetails_expected_amount_${index}`]: undefined, [`productDetails_duration_in_yrs_${index}`]: undefined, productDetailsData, productIsLoading: false, productShowMessage: true, productMessage: data.msg, messageColor: 'red'})
                    } else {
                        this.setState({[`productDetailsEditable_${index}`]: true, productIsLoading: false, productShowMessage: true, productMessage: data.msg, messageColor: 'red'})
                    }
                }
            }
        })
        .catch(error => {
            console.log(error)
            this.setState({[`productDetailsEditable_${index}`]: true, messageColor: 'red'})
        })
    }

    addProductDetail = () => {
        let productDetailsData = this.state.productDetailsData;
        let myData = {}
        myData.product = '';
        myData.expected_amount = '';
        myData.duration_in_yrs = '';
        myData.added = true;
        productDetailsData.push(myData)
        let totalProductDetails = productDetailsData.length - 1;
        let myKey1 = `productDetails_product_${totalProductDetails}`;
        let myKey2 = `productDetails_expected_amount_${totalProductDetails}`;
        let myKey3 = `productDetails_duration_in_yrs_${totalProductDetails}`;
        let myKey4 = `productDetails_added_${totalProductDetails}`;
        this.setState({productDetailsData, [`productDetailsEditable_${totalProductDetails}`]: true, [myKey1]: '', [myKey2]: '', [myKey3]: '', [myKey4]: true})
    }

    prepareProductData = async (myProductDetailsData) => {
        let productDetailsData = []
        let productDetailsKey = []

        for(let i = 0; i < myProductDetailsData.length; i++) {
            if('expexted_sale' in myProductDetailsData[i]) {
                let expectedSaleData = myProductDetailsData[i]['expexted_sale'];
                console.log(expectedSaleData)
                for(let j = 0; j < expectedSaleData.length; j++) {
                    let myData = {};
                    for (let key of Object.keys(expectedSaleData[j])) {
                        let myKey = `productDetails_${key}_${j}`;
                        this.setState({[myKey]: expectedSaleData[j][key]})
                        myData[key] = expectedSaleData[j][key];
                        productDetailsKey.push(myKey)
                    }
                    productDetailsData.push(myData);
                }
            }
        }

        console.log(productDetailsData)
        this.setState({productDetailsKey, productDetailsData}, () => {
            this.setState({productShowMessage: false, productMessage: '', productIsLoading: false, productIsLoadingMessage: ''})
        })
    }

    getProductDetailsData = async () => {
        this.setState({productEditable: false, productShowMessage: false, productMessage: '', productIsLoading: true, productIsLoadingMessage: 'Please wait while we load the data...'})

        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/lead_details?lead_id=${this.state.selectedLeadId}`);
		} catch (error) {
			console.log(error)
			this.setState({productShowMessage: true, productMessage: 'Some error occured while fetching the product details.', productIsLoading: false, productIsLoadingMessage: '', messageColor: 'red'})
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
            console.log(myResponse)
            this.setState({productShowMessage: true, productMessage: 'Some error occured while fetching the product details.', productIsLoading: false, productIsLoadingMessage: '', messageColor: 'red'})
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let myproductData = jsonResp.res[0]['Product Details'];
                await this.prepareProductData(myproductData)
			} else {
                this.setState({productShowMessage: true, productMessage: 'Some error occured while fetching the product details.', productIsLoading: false, productIsLoadingMessage: '', messageColor: 'red'})
            }
		} else {
            this.setState({productShowMessage: true, productMessage: 'Some error occured while fetching the product details.', productIsLoading: false, productIsLoadingMessage: '', messageColor: 'red'})
        }
    }

    getSubProductList = async (token) => {
        let myResponse = null;
		try {
            myResponse = await fetch(`/api/tsr/sub_product_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			this.setState({isLoading: false})
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let subProductList = jsonResp.res;
                this.setState({subProductList: subProductList})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getToken = async () => {
        let token = await localStorage.getItem('AccessToken');
        if(token && token != 'undefined') {
            console.log(token)
            return token;
        } else {
            localStorage.removeItem('AccessToken');
            this.props.history.push('login');
        }
    }

    componentWillReceiveProps(props) {
        const { productTabClicked, selectedLeadId, clearMessageStateTabs } = this.props;
        if (props.clearMessageStateTabs !== clearMessageStateTabs) {
            this.setState({productIsLoading: false, productIsLoadingMessage: '', productShowMessage: false, productMessage: ''})
        }
        if (props.productTabClicked !== productTabClicked) {
            this.getProductDetailsData();
        }
        if(props.selectedLeadId !== selectedLeadId) {
            this.setState({selectedLeadId})
        }
    }

    async componentDidMount() {
        console.log('Product mounted!')
        let token = await this.getToken();
        this.setState({token})
        await this.getSubProductList(token);
        await this.getProductDetailsData();
    }

	render() {
		const { classes } = this.props;

		return (
            <React.Fragment>
                {this.state.productIsLoading ? (
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center'}}>
                        <CircularProgress color='#256cd6' size='50px' style={{color: '#256cd6'}} />
                        <Typography variant="body2" style={{color: '#256cd6'}}>
                            {this.state.productIsLoadingMessage}
                        </Typography>
                    </div>
                ) : (
                    <React.Fragment>
                        {!!this.state.productShowMessage &&
                            <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginTop: 20}}>
                                <div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', color: this.state.messageColor ? this.state.messageColor : '#256cd6'}}>
                                    <span style={{fontSize: 20}}>{this.state.productMessage}</span>
                                </div>
                            </div>
                        }
                        <FormGroup row style={{margin: 20}}>
                            <Button variant="contained" color="primary" style={{marginRight: 10}} onClick={this.addProductDetail}>
                                Add
                            </Button>
                        </FormGroup>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20,}}>
                            {this.state.productDetailsData.map((data, index) => (
                                <React.Fragment>
                                    <div style={{display: 'flex', flexDirection: 'row', paddingRight: 20,}}>
                                        <div style={{padding: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                            {this.state[`productDetailsEditable_${index}`] ? (
                                                <React.Fragment>
                                                    <Button variant="contained" color="primary" style={{marginRight: 10}} onClick={() => this.saveProductDetailsData(index)}>
                                                        Save
                                                    </Button>
                                                    <Button variant="contained" color="secondary" style={{marginLeft: 10}} onClick={() => this.toggleEdit(`productDetailsEditable_${index}`)}>
                                                        Cancel
                                                    </Button>
                                                </React.Fragment>
                                            ) : (
                                                <Button variant="contained" color="primary" style={{marginRight: 10}} onClick={() => this.toggleEdit(`productDetailsEditable_${index}`)}>
                                                    Edit
                                                </Button>
                                            )}
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', margin: 20, borderWidth: 1, borderColor: '#f1f1f1', borderStyle: 'solid' }}>
                                            {Object.entries(data).map(([key,value],i) => (
                                                (!(ProductDetailsKeysNotToCheck.includes(key))) && (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`productDetails_${i}`}>
                                                            <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center'}}>
                                                                {this.context.language == 'en_US' ? ProductDetailsMapping[key] : ProductDetailsMapping_Vt[key]}
                                                            </Typography>
                                                            {this.state[`productDetailsEditable_${index}`] ? (
                                                                PersonalDetailsDropdowns.includes(key) ? (
                                                                    <FormControl style={{minWidth: 120}}>
                                                                        <Select
                                                                            labelId={`productDetails_${key}-select-label`}
                                                                            id={`productDetails_${key}-select-label`}
                                                                            value={this.state[`productDetails_${key}_${index}`]}
                                                                            onChange={event => {this.changeText(`productDetails_${key}_${index}`, event.target.value)}}
                                                                        >
                                                                            {this.state.subProductList.map((item) => (
                                                                                <MenuItem key={`subproductlist_${item.id}`} value={item.id}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                ) : (
                                                                    <TextField id={`outlined-basic-${key}`} label={ProductDetailsMapping[key]} variant="outlined" onChange={event => this.changeText(`productDetails_${key}_${index}`, event.target.value)} value={this.state[`productDetails_${key}_${index}`]} />
                                                                )
                                                            ) : (
                                                                (key == 'product') ? (
                                                                    this.state.subProductList.map((item) => (
                                                                        item.id == value ? item.name : item.name == value ? item.name : null
                                                                    ))
                                                                ) : (
                                                                    <Typography variant="body2" className={classes.title} style={{paddingLeft: 20}}>
                                                                        {value}
                                                                    </Typography>
                                                                )
                                                            )}
                                                        </div>
                                                        <Divider orientation="vertical" flexItem />
                                                    </React.Fragment>
                                                )
                                            ))}
                                        </div>
                                        <Divider />
                                    </div>
                                    {/* <Divider orientation="vertical" flexItem /> */}
                                </React.Fragment>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(TabProduct));
