import React, { Component } from "react";
import { withRouter, } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { OdooContext } from "../contexts/OdooContext";
import { DispositionHistoryMapping, DispositionHistoryMapping_Vt, DispositionCallMapping } from './utils/Common';

const styles = (theme) => ({
    tableContainer: {
        maxHeight: 440,
    },
})

function containsObjectHist(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].id === obj.id) {
            return true;
        }
    }

    return false;
}

function isValidHttpUrl(string) {
    let url;
  
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
  
    console.log(url);

    return url.protocol === "http:" || url.protocol === "https:";
}

class TabHistory extends Component {

    static contextType = OdooContext;

    constructor(props) {
        super(props)
        this.state = {
            historyIsLoading: true,
            historyIsLoadingMessage: 'Please wait while we load the data...',
            historyShowMessage: false,
            historyMessage: '',
            historyEditable: false,
            columnsHistoryDisp: [],
            columnsHistoryDisp_Vt: [],
            rowsHistoryDisp: [],
            pageHistoryDisp: 0,
            rowsPerPageHistoryDisp: 10,
            columnsHistoryCall: [],
            rowsHistoryCall: [],
            pageHistoryCall: 0,
            rowsPerPageHistoryCall: 10,
            selectedLeadId: this.props.selectedLeadId,
        }
    }

    handleChangePageHistoryDisp = (event, newPage) => {
        this.setState({pageHistoryDisp: newPage});
    };

    handleChangePageHistoryCall = (event, newPage) => {
        this.setState({pageHistoryCall: newPage});
    };
    
    handleChangeRowsPerPageHistoryDisp = (event) => {
        this.setState((prevState) => ({
            rowsPerPageHistoryDisp: event.target.value,
            pageHistoryDisp: 0
        }))
    };

    handleChangeRowsPerPageHistoryCall = (event) => {
        this.setState((prevState) => ({
            rowsPerPageHistoryCall: event.target.value,
            pageHistoryCall: 0
        }))
    };

    prepareHistoryData = async (myHistoryData) => {
        let rowsHistoryDisp = [];
        let columnsHistoryDisp = [];
        let columnsHistoryDisp_Vt = [];
        let rowsHistoryCall = [];
        let columnsHistoryCall = [];

        for(let i = 0; i < myHistoryData.length; i++) {
            for (let key of Object.keys(myHistoryData[i])) {
                if(key == 'dispo_hist') {
                    let myData = myHistoryData[i][key];
                    for(let j = 0; j < myData.length; j++) {
                        let valuePair = {};
                        for (let key1 of Object.keys(myData[j])) {

                            let keyPair = {};
                            let keyName = `history_Dispo_${key1}`;
                            keyPair.id = keyName;
                            keyPair.label = DispositionHistoryMapping[key1];
                            keyPair.minWidth = 170;
                            keyPair.align = 'left';
                            if(!containsObjectHist(keyPair, columnsHistoryDisp)) {
                                columnsHistoryDisp.push(keyPair);
                            }
                            let keyPair_Vt = {};
                            let keyName_Vt = `history_Dispo_${key1}`;
                            keyPair_Vt.id = keyName_Vt;
                            keyPair_Vt.label = DispositionHistoryMapping_Vt[key1];
                            keyPair_Vt.minWidth = 170;
                            keyPair_Vt.align = 'left';
                            if(!containsObjectHist(keyPair_Vt, columnsHistoryDisp_Vt)) {
                                columnsHistoryDisp_Vt.push(keyPair_Vt);
                            }
                            valuePair[keyName] = myData[j][key1];
                        }
                        rowsHistoryDisp.push(valuePair)
                    }
                } else if (key == 'call_hist') {
                    let myData = myHistoryData[i][key];
                    for(let j = 0; j < myData.length; j++) {
                        let valuePair = {};
                        for (let key1 of Object.keys(myData[j])) {
                            let keyPair = {};
                            let keyName = `history_call_${key1}`;
                            keyPair.id = keyName;
                            keyPair.label = DispositionCallMapping[key1];
                            keyPair.minWidth = 170;
                            keyPair.align = 'left';
                            if(!containsObjectHist(keyPair, columnsHistoryCall)) {
                                columnsHistoryCall.push(keyPair);
                            }
                            valuePair[keyName] = myData[j][key1];
                        }
                        rowsHistoryCall.push(valuePair)
                    }
                }
            }
        }

        this.setState({rowsHistoryDisp, columnsHistoryDisp, columnsHistoryDisp_Vt, rowsHistoryCall, columnsHistoryCall}, () => {
            this.setState({historyShowMessage: false, historyMessage: '', historyIsLoading: false, historyIsLoadingMessage: ''})
        })
    }

    getHistoryData = async () => {
        this.setState({historyEditable: false, historyShowMessage: false, historyMessage: '', historyIsLoading: true, historyIsLoadingMessage: 'Please wait while we load the data...'})

        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/lead_details?lead_id=${this.state.selectedLeadId}`);
		} catch (error) {
			console.log(error)
			this.setState({historyShowMessage: true, historyMessage: 'Some error occured while fetching the history details.', historyIsLoading: false, historyIsLoadingMessage: ''})
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
            console.log(myResponse)
            this.setState({historyShowMessage: true, historyMessage: 'Some error occured while fetching the history details.', historyIsLoading: false, historyIsLoadingMessage: ''})
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let myhistoryData = jsonResp.res[0]['History'];
                await this.prepareHistoryData(myhistoryData)
			} else {
                this.setState({historyShowMessage: true, historyMessage: 'Some error occured while fetching the history details.', historyIsLoading: false, historyIsLoadingMessage: ''})
            }
		} else {
            this.setState({historyShowMessage: true, historyMessage: 'Some error occured while fetching the history details.', historyIsLoading: false, historyIsLoadingMessage: ''})
        }
    }

    getToken = async () => {
        let token = await localStorage.getItem('AccessToken');
        if(token && token != 'undefined') {
            console.log(token)
            return token;
        } else {
            localStorage.removeItem('AccessToken');
            this.props.history.push('login');
        }
    }

    componentWillReceiveProps(props) {
        const { historyTabClicked, selectedLeadId } = this.props;
        if (props.historyTabClicked !== historyTabClicked) {
            this.getHistoryData();
        }
        if(props.selectedLeadId !== selectedLeadId) {
            this.setState({selectedLeadId})
        }
    }

    async componentDidMount() {
        console.log('History mounted!')
        let token = await this.getToken();
        this.setState({token})
        await this.getHistoryData();
    }

	render() {
		const { classes } = this.props;

		return (
            <React.Fragment>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginTop: 20}}>
                    <Typography variant="body2" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start'}} align='left'>
                        {this.context.language == 'en_US' ? 'Disposition History' : 'lịch sử trạng thái'}
                    </Typography>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {this.context.language == 'en_US' ? 
                                        this.state.columnsHistoryDisp.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, background: '#3f51b5', color: '#fff' }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))
                                    : (
                                        this.state.columnsHistoryDisp_Vt.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, background: '#3f51b5', color: '#fff' }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rowsHistoryDisp.slice(this.state.pageHistoryDisp * this.state.rowsPerPageHistoryDisp, this.state.pageHistoryDisp * this.state.rowsPerPageHistoryDisp + this.state.rowsPerPageHistoryDisp).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {this.state.columnsHistoryDisp.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : (isValidHttpUrl(value) ? <a href={value}>{value}</a> : value)}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {this.state.rowsHistoryDisp.length == 0 ? (
                        <Typography variant="body2" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start', paddingLeft: 10}} align='left'>
                            No call disposition to show
                        </Typography>
                    ) : (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={this.state.rowsHistoryDisp.length}
                            rowsPerPage={this.state.rowsPerPageHistoryDisp}
                            page={this.state.pageHistoryDisp}
                            onChangePage={this.handleChangePageHistoryDisp}
                            onChangeRowsPerPage={this.handleChangeRowsPerPageHistoryDisp}
                        />
                    )}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginTop: 20}}>
                    <Typography variant="body2" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start'}} align='left'>
                        Call History
                    </Typography>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {this.state.columnsHistoryCall.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, background: '#3f51b5', color: '#fff' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rowsHistoryCall.slice(this.state.pageHistoryCall * this.state.rowsPerPageHistoryCall, this.state.pageHistoryCall * this.state.rowsPerPageHistoryCall + this.state.rowsPerPageHistoryCall).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {this.state.columnsHistoryCall.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : (isValidHttpUrl(value) ? <a href={value} target="_blank">{value}</a> : value)}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {this.state.rowsHistoryCall.length == 0 ? (
                        <Typography variant="body2" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start', paddingLeft: 10}} align='left'>
                            No call history to show
                        </Typography>
                    ) : (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={this.state.rowsHistoryCall.length}
                            rowsPerPage={this.state.rowsPerPageHistoryCall}
                            page={this.state.pageHistoryCall}
                            onChangePage={this.handleChangePageHistoryCall}
                            onChangeRowsPerPage={this.handleChangeRowsPerPageHistoryCall}
                        />
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(TabHistory));
