const GeneralDetailsMapping = {
    "id":"ID",
    "name":	"Name",
    "disposition":	"Dispositon",
    "sub_disposition":	"Sub Disposition",
    "phone":	"Phone",
    "campaign":	"Campaign",
    "sales_team":	"Sales Team",
    // "product_type":	"Product Type",
    "appo_date":	"Appointment Date",
    "no_of_visits":	"No Of Visits",
    "email":	"Email",
    "description": "Internal Notes",
    "remarks": "Remarks",
    "channel": "Channel",
    "callback_date": "Callback Date",
    "meeting_city_id": "Meeting City",
    "meeting_district_id": "Meeting District",
    "existing_credit_card": "Existing Credit Card?",
}

const GeneralDetailsMapping_Vt = {
    "id":"ID",
    "name":"Tên",
    "phone":"điện thoại",
    "campaign":"Chiến dịch",
    "sales_team":"Đội ngũ bán hàng",
    "disposition":"Bố trí",
    "sub_disposition":"bố trí phụ",
    "appo_date":"Ngày hẹn",
    "no_of_visits":"không có chuyến thăm",
    "email":"Email",
    // "product_type":"Loại sản phẩm",
    "remarks":"Nhận xét",
    "description":"Ghi chú",
    "channel": "Channel",
    "callback_date": "Ngày gọi lại",
    "meeting_city_id": "Gặp gỡ Tp",
    "meeting_district_id": "Quận họp",
    "existing_credit_card": "Bạn có thẻ tín dụng hiện tại không?",
};

const GeneralDetailsToSaveMapping = {
    "disposition":	"Dispositon",
    "sub_disposition":	"Sub Disposition",
    "appo_date":	"Appointment Date",
    "no_of_visits":	"No Of Visits",
    "email":	"Email",
    "description": "Internal Notes",
    "remarks": "Remarks",
    // "product_type":	"Product Type",
    "name": "Name",
    "channel": "Channel",
    "callback_date": "Callback Date",
    "meeting_city_id": "Meeting City",
    "meeting_district_id": "Meeting District",
    "existing_credit_card": "Existing Credit Card?",
}

const PersonalDetailsMapping = {
    "street":	"Street",
    "street_2":	"Street2",
    "city":	"City",
    "state": "State",
    "country": "Country",
    "zip":	"Zip",
    "birth_date":	"Date Of Birth",
    "age": "Age",
    "marital_status":	"Marital Status",
    "monthly_income":	"Monthly Income",
    "annual_income":	"Annual Income",
    "income":	"Income Range",
    "income_type":	"Income Type",
    "salary_type":	"Salary Type",
    "no_of_children":	"No of Children",
    "no_of_family_mem":	"No of family members",
    "work_contr": "Labour Contract",
    "salary_type": "Salary type",
    "residence_type": "Residence Type",
    "gender": "Gender",
    "national_id": "National ID",
    "x_district": "District",
    "address": 'Address',
    "voter_id": 'Voter ID',
    "passport_id": 'Passport',
    "pan_no": 'Pan No',
    "occupation": "Occupation",
    "occupation_1": "Occupation 1",
    "occupation_2": "Occupation 2",
    "other_occupation": "Other Occupation",
}

const PersonalDetailsMapping_Vt = {
    "national_id":"National ID",
    "gender":"giới tính",
    "residence_type":"Loại nhà ở",
    "street":"Địa chỉ",
    "street_2":"Địa chỉ",
    "city":"Thành phố",
    "state":"tiểu bang",
    "country":"Quốc gia",
    "zip":"Mã bưu điện",
    "birth_date":"Ngày sinh",
    "age":"tuổi tác",
    "marital_status":"tình trạng hôn nhân",
    "monthly_income":"thu nhập hàng tháng",
    "annual_income":"Thu nhập hàng năm",
    "income_type":"loại thu nhập",
    "income":"thu nhập",
    "no_of_children":"Số trẻ",
    "no_of_family_mem":"không có thành viên gia đình",
    "work_contr":"Hợp đồng lao động",
    "salary_type":"loại lương",
    "x_district": "District",
    "address": 'địa chỉ số',
    "voter_id": 'cử tri',
    "passport_id": 'Hộ chiếu',
    "pan_no": 'chảo không',
    "occupation": "Occupation",
    "occupation_1": "Occupation 1",
    "occupation_2": "Occupation 2",
    "other_occupation": "Other Occupation",
}

const GeneralDetailsDropdowns = ['disposition', 'sub_disposition', 'no_of_visits', 'channel', 'meeting_city_id', 'meeting_district_id', 'existing_credit_card',];
const PersonalDetailsDropdowns = ['marital_status', 'income', 'income_type', 'salary_type', 'city', 'gender', 'residence_type', 'state', 'product', 'work_contr', 'country', 'voter_id', 'passport_id', 'occupation', 'occupation_1', 'occupation_2',];
const ProductDetailsKeysNotToCheck = ['id', 'added']
const PersonalDetailsKeysNotToCheck = ['partner_comapny_experience', 'partner_customer_family', 'partner_customer_education', 'type']
const DateValues = ['personal_partnerCompanyExperience_employment_from', 'personal_partnerCompanyExperience_employment_to', 'personal_partnerCustomerFamily_birth_date_sp', 'loanInfo_vehicleRegistrationDetails_vehicle_pur_date', 'personal_partnerCustomerFamily_dob1', 'personal_partnerCustomerFamily_dob2', 'personal_partnerCustomerFamily_dob3']
const DateKeyMapping = {'personal_partnerCompanyExperience_employment_from': 'Employment Start Date', 'personal_partnerCompanyExperience_employment_to': 'Employment End Date', 'personal_partnerCustomerFamily_birth_date_sp': 'Birth Date', 'loanInfo_vehicleRegistrationDetails_vehicle_pur_date': 'Date/Month of Vehicle Purchase', 'personal_partnerCustomerFamily_dob1': 'Date of birth 1', 'personal_partnerCustomerFamily_dob2': 'Date of birth 2', 'personal_partnerCustomerFamily_dob3': 'Date of birth 3',}

const PartnerCompanyExperienceMapping = {
    "company_name": "Company Name",
    "type_company": "Company Type",
    "designation": "Designation",
    "employee_type": "Employee Type",
    "tax_paid_by_employee": "Tax paid by Employee",
    "employment_from": "Employment Start Date",
}

const PartnerCompanyExperienceMapping_Vt = {
    "company_name":"Tên công ty",
    "type_company":"loại hình doanh nghiệp",
    "designation":"sự chỉ định",
    "employee_type":"loại nhân viên",
    "tax_paid_by_employee":"thuế do nhân viên nộp",
    "employment_from":"việc làm từ",
}

const PartnerCompanyExperienceDropdowns = ['type_company', 'employee_type'];
const PartnerCompanyExperienceKeysNotToCheck = ['partner_comapny_experience', 'partner_customer_family', 'partner_customer_education', 'type']

const PartnerCustomerFamilyMapping = {
    "birth_date_sp": "Date of Birth",
    "mobile": "Mobile",
    "spouse_name": "Spouse Name",
    "child_name1": 'Child name 1',
    "child_name2": 'Child name 2',
    "child_name3": 'Child name 3',
    "dob1": 'Date of birth 1',
    "dob2": 'Date of birth 2',
    "dob3": 'Date of birth 3',
    "child_gender1": 'Child Gender 1',
    "child_gender2": 'Child Gender 2',
    "child_gender3": 'Child Gender 3',
}

const PartnerCustomerFamilyMapping_Vt = {
    "birth_date_sp": "Ngày sinh",
    "mobile": "di động",
    "spouse_name": "Spouse Name",
    "child_name1": 'Tên con 1',
    "child_name2": 'Tên con 2',
    "child_name3": 'Tên con 3',
    "dob1": 'Ngày sinh 1',
    "dob2": 'Ngày sinh 2',
    "dob3": 'Ngày sinh 3',
    "child_gender1": 'Giới tính trẻ em 1',
    "child_gender2": 'Giới tính trẻ em 2',
    "child_gender3": 'Giới tính trẻ em 3',
}

const PartnerCustomerFamilyDropdowns = ['child_gender1', 'child_gender2', 'child_gender3'];
const PartnerCustomerFamilyKeysNotToCheck = ['partner_comapny_experience', 'partner_customer_family', 'partner_customer_education', 'type']

const PartnerCustomerEducationMapping = {
    "institution_name": "Instituion Name",
    "degree": "Degree",
}

const PartnerCustomerEducationMapping_Vt = {
    "institution_name":"Tên tổ chức",
    "degree":"khóa học cử nhân",
}

const PartnerCustomerEducationDropdowns = [];
const PartnerCustomerEducationKeysNotToCheck = ['partner_comapny_experience', 'partner_customer_family', 'partner_customer_education', 'type']

const DispositionHistoryMapping = {
    "disposition": "Disposition",
    "sub_disposition": "Sub Disposition",
    "name": "Name",
}

const DispositionHistoryMapping_Vt = {
    "disposition":"trạng thái",
    "sub_disposition":"trạng thái phụ",
    "name":"Tên",
}

const DispositionCallMapping = {
    "disposition": "Disposition",
    "sub_disposition": "Sub Disposition",
    "name": "Name",
    "call_started_time": "Call Start Time",
    "call_end_time": "Call End Time",
    "call_duration": "Call Duration",
    "record_url": "Record URL"
}

const ProductDetailsMapping = {
    "id": "ID",
    "product": "Product",
    "expected_amount": "Expected Amount",
    "duration_in_yrs": "Duration in Years",
}

const ProductDetailsMapping_Vt = {
    "id": "ID",
    "product":"Sản phẩm",
    "expected_amount":"số tiền dự kiến",
    "duration_in_yrs":"thời hạn tính bằng năm",
}

const LoanDetailsMapping = {
    "loan_existing": "Loan existing",
    "life_insurance_contr": "Life insurance Contract",
    "term_6months": "Insurance Term",
    "insure1yr": "Annual Premium",
    "business_reg": "Business Registration",
    "business": "Business",
    "cons_dur_loan": "Consumer Durable Loan",
}

const ExistingLoansMapping = {
    "bank":	"Financial Institution Name",
    "other_bnk": "Other Bank",
    "active_loan": "Active",
    "loan_amnt": "Loan Amount",
    "emi_amnt":	"EMI Amount",
    "tenor": "Tenor",
    "loan_contract": "Loan Contract",
    "payment_receipt": "Payement Receipt",
    "loan_type": "Type",
}

const BillDetailsMapping = {
    "utility_bills": "Utility Bills",
    "cable_tv_bill": "Cable Tv Bills",
    "net_bill": "Net Bills",
    "water_bill": "Water Bills",
    "evn_bill": "EVN Bill Name",
    "evn_bill_holder": "EVN Bill Holder",
    "evn_bill_amnt": "EVN Bill Amount",
}

const BillDetailsDropdowns = ['utility_bills', 'evn_bill', 'evn_bill_holder', 'evn_bill_amnt'];

const VehicleRegistrationDetailsMapping = {
    "vehicle_reg": "Vehicle Registration",
    "vehicle_reg_type":	"Vehicle Registration Type",
    "vehicle_pur_date":	"Date/Month of Vehicle Purchase",
    "vehicle_value": "Vehicle Value",
    "other_veh_owner": "Vehicle Owner Name",
    "other_veh_contact": "Vehicle Owner Contact Number",
}

const VehicleRegistrationDetailsDropdowns = ['vehicle_reg', 'vehicle_reg_type'];

const BankDetailsMapping = {
    "bnk_stmnt":	"Bank Statement",
    "bnk": "Bank Name",
    "bnk_m1":	"Month 1",
    "big_tran_m1":	"Month 1 Biggest Transaction (Credit)",
    "small_tran_m1":	"Month 1 Smallest Transaction (Credit)",
    "bnk_m2":	"Month 2",
    "big_tran_m2":	"Month 2 Biggest Transaction (Credit)",
    "small_tran_m2":	"Month 2 Smallest Transaction (Credit)",
    "bnk_m3":	"Month 3",
    "big_tran_m3":	"Month 3 Biggest Transaction (Credit)",
    "small_tran_m3":	"Month 3 Smallest Transaction (Credit)",
    "bnk_m4":	"Month 4",
    "small_tran_m4": "Month 4 Smallest Transaction (Credit)",
    "big_tran_m4": "Month 4 Biggest Transaction (Credit)",
}

const BankDetailsDropdowns = ['bnk_stmnt'];

const LeadscoreDetailsMapping = {
    result: "Result",
    score_age: "Score Age",
    score_appoint_quality: "Score Appoint Quality",
    score_call_length: "Score Call Length",
    score_income_range: "Score Income Range",
    score_interest_level: "Score Interest Level",
    score_note: "Score Note",
    score_occupation: "Score Occupation",
    score_product: "Score Product",
    time_of_scoring: "Time of Scoring",
    total: "Total",
}

const availableValues = {
	loanInfo_existingLoans_bank: [
        {key: 'FE Credit', value: 'FE Credit', name: 'FE Credit',},
        {key: 'Easy Credit', value: 'Easy Credit', name: 'Easy Credit',},
        {key: 'M Credit', value: 'M Credit', name: 'M Credit',},
        {key: 'Maritime Bank', value: 'Maritime Bank', name: 'Maritime Bank',},
        {key: 'VP Bank', value: 'VP Bank', name: 'VP Bank',},
        {key: 'Handico Finance', value: 'Handico Finance', name: 'Handico Finance',},
        {key: 'Mirae Asset', value: 'Mirae Asset', name: 'Mirae Asset',},
        {key: 'Home Credit', value: 'Home Credit', name: 'Home Credit',},
        {key: 'Shinhan Vietnam Finance', value: 'Shinhan Vietnam Finance', name: 'Shinhan Vietnam Finance',},
        {key: 'SHBank Finance', value: 'SHBank Finance', name: 'SHBank Finance',},
        {key: 'Vietcredit', value: 'Vietcredit', name: 'Vietcredit',},
        {key: 'HDB Bank', value: 'HDB Bank', name: 'HDB Bank',},
        {key: 'ACB Bank', value: 'ACB Bank', name: 'ACB Bank',},
        {key: 'Agri Bank', value: 'Agri Bank', name: 'Agri Bank',},
        {key: 'Others', value: 'Others', name: 'Others',},
    ],
    loanInfo_existingLoans_active_loan: [
        {key: 'yes_active_loan', value: 'yes', name: 'Yes'},
        {key: 'no_active_loan', value: 'no', name: 'No'},
    ],
    loanInfo_existingLoans_loan_contract: [
        {key: 'yes_loan_contract', value: 'yes', name: 'Yes'},
        {key: 'no_loan_contract', value: 'no', name: 'No'},
    ],
    loanInfo_existingLoans_payment_receipt: [
        {key: 'yes_payment_receipt', value: 'yes', name: 'Yes'},
        {key: 'no_payment_receipt', value: 'no', name: 'No'},
    ],
    loanInfo_existingLoans_loan_type: [
        {key: 'Car Loan', value: 'Car Loan', name: 'Car Loan'},
        {key: 'House Loan', value: 'House Loan', name: 'House Loan'},
        {key: 'Bike Loan', value: 'Bike Loan', name: 'Bike Loan'},
        {key: 'Personal Loan', value: 'Personal Loan', name: 'Personal Loan'},
        {key: 'Consumer Durable Loan', value: 'Consumer Durable Loan', name: 'Consumer Durable Loan'},
    ],
    loanInfo_billDetails_utility_bills: [
        {key: 'yes_utility_bills', value: 'yes', name: 'Yes'},
        {key: 'no_utility_bills', value: 'no', name: 'No'},
        {key: 'np_utility_bills', value: 'np', name: 'Not Provided'},
    ],
    loanInfo_billDetails_evn_bill: [
        {key: 'yes_bnk_stmnt', value: 'yes', name: 'Yes'},
        {key: 'no_bnk_stmnt', value: 'no', name: 'No'},
        {key: 'np_bnk_stmnt', value: 'np', name: 'Not Provided'},
    ],
    loanInfo_billDetails_evn_bill_holder: [
        {key: 'self_evn_bill', value: 'self', name: 'Self'},
        {key: 'spouse_evn_bill', value: 'spouse', name: 'Spouse'},
    ],
    loanInfo_billDetails_evn_bill_amnt: [
        {key: '1_evn_bill_amnt', value: '1', name: '>= 1 Mn VND'},
        {key: '2_evn_bill_amnt', value: '2', name: '>= 550.000 VND'},
        {key: '3_evn_bill_amnt', value: '3', name: '>= 300.000 VND'},
    ],
    loanInfo_bankDetails_bnk_stmnt: [
        {key: 'yes_bnk_stmnt', value: 'yes', name: 'Yes'},
        {key: 'no_bnk_stmnt', value: 'no', name: 'No'},
        {key: 'np_bnk_stmnt', value: 'np', name: 'Not Provided'},
    ],
    loanInfo_vehicleRegistrationDetails_vehicle_reg: [
        {key: 'yes_vehicle_reg', value: 'yes', name: 'Yes'},
        {key: 'no_vehicle_reg', value: 'no', name: 'No'},
        {key: 'np_vehicle_reg', value: 'np', name: 'Not Provided'},
    ],
    loanInfo_vehicleRegistrationDetails_vehicle_reg_type: [
        {key: 'self_vehicle_reg_type', value: 'self', name: 'Owned Self'},
        {key: 'others_vehicle_reg_type', value: 'others', name: 'Owned Others'},
    ],
    loanInfoDetails_loan_existing: [
        {key: 'yes_loan_existing', value: 'yes', name: 'Yes'},
        {key: 'no_loan_existing', value: 'no', name: 'No'},
    ],
    loanInfoDetails_life_insurance_contr: [
        {key: 'yes_life_insurance_contr', value: 'yes', name: 'Yes'},
        {key: 'no_life_insurance_contr', value: 'no', name: 'No'},
        {key: 'np_life_insurance_contr', value: 'np', name: 'Not Provided'},
    ],
    loanInfoDetails_term_6months: [
        {key: 'yes_term_6months', value: '1', name: 'Term > 6 Months'},
        {key: 'no_term_6months', value: '2', name: 'Term < 6 Months'},
    ],
    loanInfoDetails_insure1yr: [
        {key: 'yes_insure1yr', value: '1', name: 'Annual Premium >3 Mn per year'},
        {key: 'no_insure1yr', value: '2', name: 'Annual Premium <3 Mn per year'},
    ],
    loanInfoDetails_business_reg: [
        {key: 'yes_business_reg', value: 'yes', name: 'Yes'},
        {key: 'no_business_reg', value: 'no', name: 'No'},
        {key: 'np_business_reg', value: 'np', name: 'Not Provided'},
    ],
    loanInfoDetails_business: [
        {key: '1_business', value: '1', name: 'Business Time > 24 Months'},
        {key: '2_business', value: '2', name: 'Business Time > 12 and < 24 Months'},
        {key: '3_business', value: '3', name: 'Business Time > 6 and < 12 Months'},
        {key: '4_business', value: '4', name: 'Business Time > 3 and < 6 Months'},
    ],
    loanInfoDetails_cons_dur_loan: [
        {key: 'yes_cons_dur_loan', value: 'yes', name: 'Yes'},
        {key: 'no_cons_dur_loan', value: 'no', name: 'No'},
        {key: 'np_cons_dur_loan', value: 'np', name: 'Not Provided'},
    ],
    marital_status: [
        {key: 'married_marital_status', value: 'married', name: 'Married'},
        {key: 'single_marital_status', value: 'single', name: 'Single'},
        {key: 'separated_marital_status', value: 'separated', name: 'Separated'},
        {key: 'widowd_marital_status', value: 'widowd', name: 'Widowed'},
        {key: 'divorced_marital_status', value: 'divorced', name: 'Divorced'},

    ],
    income: [
        {key: '1_income', value: '1', name: '20000 INR to 30000 INR'},
        {key: '2_income', value: '2', name: '30000 INR to 40000 INR'},
        {key: '3_income', value: '3', name: '40000 INR to 50000 INR'},
        {key: '4_income', value: '4', name: '40000 INR to 50000 INR'},
        {key: '5_income', value: '5', name: '55000 INR to 65000 INR'},
        {key: '6_income', value: '6', name: '65000 INR to 70000 INR'},
        {key: '7_income', value: '7', name: '70000 INR to 80000 INR'},
        {key: '8_income', value: '8', name: '80000 INR and Above'},
    ],
    income_type: [
        {key: '1_income_type', value: '1', name: 'Salaried'},
        {key: '2_income_type', value: '2', name: 'Self Employed Professional'},
        {key: '3_income_type', value: '3', name: 'Self Employed NonProfessional'},
        {key: '4_income_type', value: '4', name: 'Government'},
        {key: '5_income_type', value: '5', name: 'Corporate'},
    ],
    salary_type: [
        {key: 'cash_income_type', value: 'cash', name: 'Cash'},
        {key: 'btransfer_income_type', value: 'btransfer', name: 'Bank Transfer'},
    ],
    voter_id: [
        {key: 'yes_voter_id', value: 'yes', name: 'Yes'},
        {key: 'no_voter_id', value: 'no', name: 'No'},
    ],
    passport_id: [
        {key: 'yes_passport_id', value: 'yes', name: 'Yes'},
        {key: 'no_passport_id', value: 'no', name: 'No'},
    ],
    gender: [
        {key: 'male_gender', value: 'male', name: 'Male'},
        {key: 'female_gender', value: 'female', name: 'Female'},
        {key: 'other_gender', value: 'other', name: 'Other'},
    ],
    residence_type: [
        {key: 'Own_residence_type', value: 'Own', name: 'Own'},
        {key: 'Rented_residence_type', value: 'Rented', name: 'Rented'},
        {key: 'Employer_residence_type', value: 'Employer', name: 'Employer'},
        {key: 'Mortgagged_residence_type', value: 'Mortgagged', name: 'Mortgagged'},
        {key: 'parental_residence_type', value: 'parental', name: 'parental'},
        {key: 'Shared_residence_type', value: 'Shared', name: 'Shared'},
    ],
    work_contr: [
        {key: 'yes_work_contr', value: 'yes', name: 'Yes'},
        {key: 'no_work_contr', value: 'no', name: 'No'},
        {key: 'np_work_contr', value: 'np', name: 'Not Provided'},
    ],
    personal_partnerCompanyExperience_type_company: [
        {key: 'government_type_company', value: 'govtment', name: 'Government'},
        {key: 'private_type_company', value: 'private', name: 'Private'},
    ],
    personal_partnerCompanyExperience_employee_type: [
        {key: 'blanked_employee_type', value: 'blanked', name: 'Blanked'},
        {key: 'salaried_employee_type', value: 'salaried', name: 'Salaried'},
        {key: 'self-employed_employee_type', value: 'self employed', name: 'Self Employed'},
    ],
    personal_partnerCustomerFamily_employment_type: [
        {key: 'none_employment_type', value: 'none', name: 'None'},
        {key: 'employed_employment_type', value: 'employed', name: 'Employed'},
        {key: 'self-employed_employment_type', value: 'self-employed', name: 'Self-Employed'},
    ],
    personal_partnerCustomerFamily_relation_name: [
        {key: 'Mother_relation_name', value: 'Mother', name: 'Mother'},
        {key: 'Father_relation_name', value: 'Father', name: 'Father'},
        {key: 'Husband_relation_name', value: 'Husband', name: 'Husband'},
        {key: 'Spouse_relation_name', value: 'Spouse', name: 'Spouse'},
        {key: 'Brother_relation_name', value: 'Brother', name: 'Brother'},
        {key: 'Sister_relation_name', value: 'Sister', name: 'Sister'},
    ],
    personal_partnerCustomerFamily_child_gender1: [
        {key: 'gender_name_male_1', value: 'male', name: 'Male'},
        {key: 'gender_name_female_1', value: 'female', name: 'Female'},
    ],
    personal_partnerCustomerFamily_child_gender2: [
        {key: 'gender_name_male_2', value: 'male', name: 'Male'},
        {key: 'gender_name_female_2', value: 'female', name: 'Female'},
    ],
    personal_partnerCustomerFamily_child_gender3: [
        {key: 'gender_name_male_3', value: 'male', name: 'Male'},
        {key: 'gender_name_female_3', value: 'female', name: 'Female'},
    ],
    channel: [
        {key: 'telesales', value: 'telesales', name: 'Telesales'},
        {key: 'fsr', value: 'fsr', name: 'FSR'},
        {key: 'vfsr', value: 'vfsr', name: 'VFSR'},
    ],
    existing_credit_card: [
        {key: 'existingCreditCard_yes', value: 'true', name: 'Yes'},
        {key: 'existingCreditCard_no', value: 'false', name: 'No'},
    ],
    occupation: [
        {key: 'occupation_1', value: '1', name: 'Manager'},
        {key: 'occupation_2', value: '2', name: 'Sales Staff'},
        {key: 'occupation_3', value: '3', name: 'Customer Service Staff'},
        {key: 'occupation_4', value: '4', name: 'Labour in Retail'},
        {key: 'occupation_5', value: '5', name: 'Labour in Shop'},
        {key: 'occupation_6', value: '6', name: 'Security Guard'},
        {key: 'occupation_7', value: '7', name: 'Service Staff'},
        {key: 'occupation_8', value: 'others', name: 'Others'},
    ],
    occupation_1: [
        {key: 'occupation_1_1', value: '1', name: 'Doctor'},
        {key: 'occupation_1_2', value: '2', name: 'Scientist'},
        {key: 'occupation_1_3', value: '3', name: 'Teacher'},
        {key: 'occupation_1_4', value: '4', name: 'Architect'},
        {key: 'occupation_1_5', value: '5', name: 'Interior Designer'},
        {key: 'occupation_1_6', value: '6', name: 'Painter'},
        {key: 'occupation_1_7', value: '7', name: 'Health Staff'},
        {key: 'occupation_1_8', value: 'others', name: 'Others'},
    ],
    occupation_2: [
        {key: 'occupation_2_1', value: '1', name: 'Bus Driver'},
        {key: 'occupation_2_2', value: '2', name: 'Cab Driver'},
        {key: 'occupation_2_3', value: '3', name: 'Bike Driver'},
        {key: 'occupation_2_4', value: '4', name: 'Grocery Shop Owner'},
        {key: 'occupation_2_5', value: '5', name: 'Porter'},
        {key: 'occupation_2_6', value: '6', name: 'Food Caterer'},
        {key: 'occupation_2_7', value: '7', name: 'Grocery Seller'},
        {key: 'occupation_2_8', value: '8', name: 'Electrician'},
        {key: 'occupation_2_9', value: '9', name: 'Mechanic'},
        {key: 'occupation_2_10', value: '10', name: 'Vegetable and Food Seller'},
        {key: 'occupation_2_11', value: '11', name: 'Clothing Retailer'},
        {key: 'occupation_2_12', value: '12', name: 'Agriculture labour'},
        {key: 'occupation_2_13', value: '13', name: 'Online Seller'},
        {key: 'occupation_2_14', value: '14', name: 'Cleaners and housework assistant'},
        {key: 'occupation_2_15', value: '15', name: 'Construction Labour'},
        {key: 'occupation_2_8', value: 'others', name: 'Others'},
    ],
};

const LeadMenu = [
    {name: 'Leads', type: 'leads', url: '/api/tsr/lead_list'},
    {name: 'Appointment Today Leads', type: 'appointmentTodayLeads', url: '/api/tsr/appointment_lead_list'},
    {name: 'Call Back Today', type: 'callBackToday', url: '/api/tsr/Callbacktoday_lead_list'},
    {name: 'Followup leads', type: 'followupLeads', url: '/api/tsr/appointment_lead_list'},
    {name: 'Missed Appointment Leads', type: 'missedAppointmentLeads', url: '/api/tsr/missed_appointment_lead_list'},
    {name: 'Not yet called List', type: 'notYetCalledList', url: '/api/tsr/notyet_called_lead_list'},
];

const CreateDropdowns = ['disposition', 'sub_disposition', 'campaign', 'sales_team', 'product', 'city', 'gender', 'marital_status', 'income', 'income_type', 'residence_type',]

const CreateDropdownsDynamic = ['disposition', 'sub_disposition', 'campaign', 'sales_team', 'product', 'city'];

const CreateDetailsKey = [
    [ "name", "phone", "email", "city",],
    [ "income", "income_type", "annual_income", "national_id",],
    [ "gender", "birth_date", "marital_status", "residence_type",],
    [ "sales_team", "campaign", ]
]

const CreateDetailsMapping = {
    "product": "Product",
    "marital_status": "Marital Status",
    "annual_income": "Annual Income",
    "income": "Income Range",
    "income_type": "Income Type",
    "residence_type": "Residence Type",
    "gender": "Gender",
    "city":	"City",
    "birth_date": "Date Of Birth",
    "disposition": "Disposition",
    "sub_disposition": "Sub Disposition",
    "email": "Email",
    "campaign": "Campaign",
    "national_id": "National ID",
    "sales_team": "Sales Team",
    "phone": "Phone",
    "name":"Name",
}

const URL = 'http://54.254.62.77/home';

const LeadListItems = ['appo_date', 'campaign', 'city', 'created_on', 'dispsoition', 'disposition', 'fpc', 'id', 'lead_source', 'name', 'phone', 'stage_id', 'sub_disposition', 'tsr'];

const LeadListItemsDropdown = ['city', 'dispsoition', 'disposition', 'sub_disposition'];

export {
    ExistingLoansMapping,
    BillDetailsMapping,
    availableValues,
    VehicleRegistrationDetailsMapping,
    BankDetailsMapping,
    LoanDetailsMapping,
    PersonalDetailsMapping,
    PersonalDetailsMapping_Vt,
    PersonalDetailsDropdowns,
    PartnerCompanyExperienceMapping,
    PartnerCompanyExperienceMapping_Vt,
    PartnerCompanyExperienceDropdowns,
    PartnerCompanyExperienceKeysNotToCheck,
    PartnerCustomerFamilyMapping,
    PartnerCustomerFamilyMapping_Vt,
    PartnerCustomerFamilyDropdowns,
    PartnerCustomerFamilyKeysNotToCheck,
    PartnerCustomerEducationMapping,
    PartnerCustomerEducationMapping_Vt,
    PartnerCustomerEducationDropdowns,
    PartnerCustomerEducationKeysNotToCheck,
    GeneralDetailsMapping,
    GeneralDetailsMapping_Vt,
    GeneralDetailsDropdowns,
    DispositionHistoryMapping,
    DispositionHistoryMapping_Vt,
    DispositionCallMapping,
    ProductDetailsMapping,
    ProductDetailsMapping_Vt,
    ProductDetailsKeysNotToCheck,
    DateValues,
    DateKeyMapping,
    GeneralDetailsToSaveMapping,
    PersonalDetailsKeysNotToCheck,
    LeadMenu,
    CreateDropdowns,
    CreateDropdownsDynamic,
    CreateDetailsMapping,
    CreateDetailsKey,
    LeadscoreDetailsMapping,
    BankDetailsDropdowns,
    BillDetailsDropdowns,
    VehicleRegistrationDetailsDropdowns,
    LeadListItems,
    LeadListItemsDropdown,
}